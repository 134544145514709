#{$app}- {
    &event {
        &__ {
            &card {
                &:last-child #{$app}-card {
                    margin-bottom: 0;
                }
            }
            &informations {
                &- {
                    &wrap {
                        padding: 20px;
                        overflow: visible;
                    }
                    &title {
                        font-size: var(--fs-title-medium);
                        font-weight: bold;
                        margin-bottom: 20px;
                    }
                    &subtitle {
                        padding: 5px 30px;
                        background: $color-tertiary;
                        display: inline-block;
                        margin-left: -30px;
                        margin-bottom: 10px;
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            border-bottom: 10px solid transparent;
                            border-top: 0px solid transparent;
                            border-right: 10px solid $color-tertiary;
                            bottom: -10px;
                            left: 0px;
                        }
                    }
                }
            }
            &information {
                margin-bottom: 20px;

                &- {
                    &label, &value {
                        word-break: break-all;
                    }
                    &label {
                        font-size: var(--fs-text);
                        font-weight: bold;
                    }
                    &value {
                    }
                }
            }
            &details {
                &- {
                    &banner {
                        @media (max-width: 768px) {
                            padding-top: 0;
                            padding-bottom: 0;
                        }
                        
                        @media (min-width: 1025px) {
                            height: 560px;
                            min-height: 560px;
                            // height: 60vh;
                            background-position: "bottom";
                            background-size: cover;
                            background-repeat: no-repeat;
                        }
                    }
                    &image {
                        height: 100%;
                        // min-height: 600px;
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                        border-radius: 30px;
                        overflow: hidden;
                    }
                    &para {
                        & span {
                            margin: 12px 0;
                            color: $color-secondary !important;
                            background: none !important;
                            font-size: inherit !important;
                            font-family: inherit !important;
                        }
                        & ol, ul {
                            margin: 1em 0 0 2em;
                        }
                    }
                    &title {
                        &::before {
                            margin: unset;
                        }
                    }
                }
            }
            &speakers {
                background: #F8F9F9;

                &- {
                    &badge {
                        font-size: var(--fs-text);
                        padding: 5px 50px;
                        background: $color-tertiary;
                        display: inline-block;
                        margin-left: -10px;
                        margin-bottom: 10px;
                        position: relative;

                        &::before {
                            content: "";
                            position: absolute;
                            border-bottom: 10px solid transparent;
                            border-top: 0px solid transparent;
                            border-right: 10px solid $color-tertiary;
                            bottom: -10px;
                            left: 0px;
                        }
                    }
                    &content {
                        padding: 20px 40px 40px;
                    }
                    &title {
                        color: #313131;
                        font-size: var(--fs-subtitle);
                        line-height: calc(var(--fs-subtitle) + 1rem);
                        font-family: $nunito;
                    }
                    &label {
                        font-size: var(--fs-title-medium);
                        line-height: calc(var(--fs-title-medium) + 1rem);
                        color: $color-primary;
                        font-weight: 600;
                    }
                }
            }
            &speaker {
                padding: 20px;
                background: #ffffff;
                border-radius: 4px;
                border: 1px solid rgba($color: #000000, $alpha: 0.16);
                position: relative;

                &::before, &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    width: 4px;
                    height: 60%;
                    background-color: #d6d6d6;
                    border-radius: 50px;
                    margin: auto;
                }
                &::before {
                    left: -2px;
                }
                &::after {
                    right: -2px;
                }

                &- {
                    &img {
                        width: 100px;
                        height: 100px;
                        border-radius: 100px;
                        background-color: rgb(233, 233, 233);
                        background-size: cover;
                        background-repeat: no-repeat;
                        background-position: center;
                        overflow: hidden;
                    }
                    &details {
                        width: calc(100% - 100px);
                        padding-left: 15px;
                        font-family: $nunito;
                    }
                    &name {
                        font-size: var(--fs-text);
                        color: #313131;
                    }
                }
            }
        }
    }
}